$popover-max-width: 500px;

@import "~font-awesome/scss/font-awesome";
@import "~bootstrap/scss/bootstrap";

.shelf-item {
  display: inline-block;
  height: 50px;
  border: 1px solid #ddd;
  border-right: none;
  &:last-child {
    border-right: 1px solid #ddd;
  }

  .shelf-item-inner {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.shelf-item-status-green {
    background-color: #75E6C4;
  }
  &.shelf-item-status-yellow {
    background-color: yellow;
  }
  &.shelf-item-status-red {
    background-color: red;
  }
}

.popover-body {
  table {
    width: 100%;
  }
}
